import HefiHero from './img/Hefi-hero-img@2x-min.png'
import Image from 'react-bootstrap/Image'

const HefiHeroimg = () => {
    return (
        <div>
            <Image src={HefiHero} alt="bright hospital entrance with doctor talking to patient" className="img-fluid" />
        </div>
    )
}

export default HefiHeroimg
