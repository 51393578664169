import "./App.css"
import Aep from "./Aep"
import Hefi from "./Hefi"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Navigation from "./components/Navigation"
import Footer from "./components/Footer"
import Home from "./components/Home"
import ScrollToTop from "./components/ScrollToTop"

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Navigation />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/aep" exact component={Aep} />
          <Route path="/hefi" exact component={Hefi} />
        </Switch>
        <Footer />
      </div>
    </Router>
  )
}

export default App
