import "./App.css"
import AepHeroimg from "./components/AepHeroimg"
import { Row, Container, Col } from "react-bootstrap"
import ParticipantBenefits from "./components/ParticipantBenefits"
import LimitedFunding from "./components/LimitedFunding"
import HowItWorks from "./components/HowItWorks"
import EligibilityAEP from "./components/EligibilityAEP"
import ContactSection from "./components/ContactSection"
import { AiFillPhone } from "react-icons/ai"
import { AiTwotoneMail } from "react-icons/ai"
import ScrollTopArrow from "./components/ScrollTopArrow"
import React, { useEffect } from 'react';

const Aep = () => {
  useEffect(() => {
    document.title = `PG&E EE Programs AEP`;
  });

  return (
    <div>
      <AepHeroimg />
      <Container>
        <Row className="p-2 pt-3">
          <Col lg={9}>
            <h3 style={{color: "#5e5e5e"}}>Advanced Energy Program (High-Tech/Bio-Tech)</h3>
            <p>
              The Advanced Energy Program (AEP) is a comprehensive energy
              efficiency solution designed to support PG&E High-Tech and Bio-Tech
              (HTBT) customers in saving money by achieving next-generation
              energy performance.
            </p>
          </Col>
          <Col lg={3}>
            <h4>Contact Us</h4>
            <div>
              <AiFillPhone className="phone-icon" role="img" aria-labelledby="phone_number" alt="phone image" />
              <span
                className="phone-link" id="phone_number"
                style={{ paddingLeft: ".5em", color: "#5e5e5e" }}
              >
                888.237.4334
              </span>
            </div>
            <div>
              <AiTwotoneMail className="mail-icon" role="img" aria-labelledby="email_box" alt="email box" />
              <span
                style={{ paddingLeft: ".5em", color: "#5e5e5e" }}
                className="mail-link" id="email_box"
              >
                AEP@resource-innovations.com
              </span>
            </div>
          </Col>
        </Row>
      </Container>

      <section className="bg-gray">
        <Container>
          <h4 className="pt-3">Our Approach</h4>
          <Row className="pb-2">
            <p>
              This unique Program provides no-cost expert technical services,
              end-to-end project support, and lucrative financial incentives to
              qualifying customers. Our team of experienced energy experts and
              highly qualified Delivery Partners, along with your PG&E Account
              Manager, will help you get projects identified, approved, funded,
              and completed so you can focus on your core business priorities.{" "}
            </p>

            <p>
              We can serve all types of facilities and space uses in your portfolio including offices, manufacturing, R&D, laboratories, clean rooms, data centers, data labs, and more. Our team has extensive experience completing energy projects across large multi-site campuses and portfolios.
            </p>

            <p>
              Our comprehensive yet streamlined approach will identify projects
              ranging from low-cost controls optimization to equipment retrofits
              and system replacements. Our team of experts can handle it all:
              from complex HVAC systems and central plants, to advanced
              lighting, specialized data center and lab equipment,
              refrigeration, sensitive process equipment and much more. Focusing on your critical business operations while dealing with the ongoing challenges of Covid-19, regulatory compliance, legislative changes, and maintaining resilience is difficult. So let us help make saving energy easy!
            </p>
          </Row>
        </Container>
      </section>
      <ParticipantBenefits />
      <LimitedFunding />
      <HowItWorks />
      <EligibilityAEP />
      <ContactSection />
      <ScrollTopArrow />
    </div>
  )
}

export default Aep
