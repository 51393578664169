import Button from "react-bootstrap/Button"
import { Row, Container } from "react-bootstrap"
import { AiOutlineCaretDown } from "react-icons/ai"

const LimitedFunding = () => {
  return (
    <div>
      <section className="bg-gray">
        <Container align="center">
          <Row className="pt-4" align="center">
            <h4>Funding is Limited – Get started today</h4>
          </Row>
          <Button className="mb-4" size="lg" href="#contact" aria-label="Contact Us">
            Contact Us
            <AiOutlineCaretDown style={{ paddingLeft: ".2em" }} role="graphics-document" title="caretdown" />
          </Button>
        </Container>
      </section>
    </div>
  )
}

export default LimitedFunding
