import { Row, Col, Container } from "react-bootstrap"
import phoneIcon from "./img/noun_contact us_3892976.png"
import emailIcon from "./img/noun_Email_3942034.png"
// import webIcon from "./img/noun_web_955626.png"
import Button from "react-bootstrap/Button"

const ContactSection = () => {
  return (
    <div>
      <Container align="center" className="p-4" id="contact">
        <Row>
          <Col md="6">
            <a href="tel:8882374334" style={{ color:"#5e5e5e"}} >
              <img src={phoneIcon} alt="Call us" />
            </a>

            <Row className="justify-content-center contact-us">
              <span className="phone-link">
                888.237.4334
              </span>
            </Row>
          </Col>
          <Col md="6">
            <a href="mailto:AEP@resource-innovations.com">
              <img src={emailIcon} alt="Email us" />
            </a>
            <Row className="justify-content-center contact-us">
              <span className="mail-link">
                AEP@resource-innovations.com
              </span>
            </Row>
          </Col>
          {/* <Col>
            <img src={webIcon} alt="" />

            <Row className="justify-content-center contact-us">tbd.com</Row>
          </Col> */}
        </Row>
        <Row>
          <Col md="3">
            <Button className="p-2 mt-5 justify-content-center d-flex align-items-center" href="https://eeprograms-production.s3.amazonaws.com/ProgramDocuments/2022/AEP+-+Application+2022+v1.pdf" style={{ height: "64px" }}>
            DOWNLOAD AEP APPLICATION</Button>
          </Col>
          <Col md="3">
            <Button className="p-2 mt-5 justify-content-center d-flex align-items-center" href="https://eeprograms-production.s3.amazonaws.com/ProgramDocuments/2022/AEP+-+Access+Agreement+2022+v2.pdf" style={{ height: "64px" }}>
            DOWNLOAD AEP ACCESS AGREEMENT</Button>
          </Col>
          <Col md="3">
            <Button className="p-2 mt-5 justify-content-center d-flex align-items-center" href="https://eeprograms-production.s3.amazonaws.com/ProgramDocuments/2022/AEP+-+High+Tech+-+Flyer+2022+v1.pdf" style={{ height: "64px" }}>
            DOWNLOAD HIGH-TECH PROGRAM BROCHURE</Button>
          </Col>
          <Col md="3">
            <Button className="p-2 mt-5 justify-content-center d-flex align-items-center" href="https://eeprograms-production.s3.amazonaws.com/ProgramDocuments/2022/AEP+-+Bio+Tech+-+Flyer+2022+v1.pdf" style={{ height: "64px" }}>
            DOWNLOAD BIO-TECH PROGRAM BROCHURE</Button>
          </Col>
        </Row>

      </Container>
    </div>
  )
}

export default ContactSection
