import Button from "react-bootstrap/Button"
import heroImage from "./img/landing-herox2-min.png"
import Card from "react-bootstrap/Card"
import { AiOutlineCaretDown } from "react-icons/ai"

const Header = () => {
  return (
    <div>
      <Card className="hero-styles" border="none" >
        <Card.Img src={heroImage} alt="Card image" />
        <Card.ImgOverlay></Card.ImgOverlay>
        <div className="hero-overlay">
          <h3 id="headerText" className="p-4">PROGRAMS THAT BENEFIT YOU AND YOUR CUSTOMERS</h3>
          {/* <button className="button-style">Learn More</button> */}
          <Button id="headerButton" href="#learn-more" size="lg" className="button-style mx-4">
            Learn More
            <AiOutlineCaretDown style={{ paddingLeft: ".2em" }} role="img" alt="down arrow" />
          </Button>
        </div>
      </Card>

    </div>
  )
}

export default Header
