import aepHeroImage from "./img/aep-hero-imgx2-min.png"
import Image from "react-bootstrap/Image"

const AepHeroimg = () => {
  return (
    <div>
      <Image src={aepHeroImage} className="img-fluid" alt="" />
    </div>
  )
}

export default AepHeroimg
