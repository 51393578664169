import { Container, Col, Row } from "react-bootstrap"
import nounSupportIcon from "./img/noun_support_3306391.png"
import partBenefitsImage from "./img/part-benefits-img.png"
import customerSupportIcon from "./img/noun_customer service_1012182.png"
import billSavingsIcon from "./img/noun_savings_2225629.png"
import incentivesIcon from "./img/noun_finance_3810617.png"
import nonenergyBenefitsIcon from "./img/noun_graphic_3079785.png"

const ParticipantBenefits = () => {
  return (
    <div>
      <Container align="center">
        <Row>
          <Col xl="6" className="p-0">
            <img
              src={partBenefitsImage}
              alt="contractor shaking hands with customers and smiling"
              className="benefits-img"
            />
          </Col>
          <Col xs lg="5">
            <Row>
              <h4 align="center" className="p-3">
                Participant Benefits
              </h4>
              <Col sm="4" align="center">
                <img src={nounSupportIcon} alt="" />
              </Col>
              <Col className="align-self-center p-0">
                <p className="benefits-styles">
                  Expert technical assistance at no cost
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="4" align="center">
                <img src={billSavingsIcon} alt="" />
              </Col>
              <Col className="align-self-center p-0">
                <p className="benefits-styles">
                  Bill savings and quantified GHG reductions
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="4" align="center">
                <img src={incentivesIcon} alt="" />
              </Col>
              <Col className="align-self-center p-0">
                <p className="benefits-styles">
                  Financial incentives to reduce project costs. $0.12/kWh and $1.00/therm
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="4" align="center">
                <img src={nonenergyBenefitsIcon} alt="" />
              </Col>
              <Col className="align-self-center p-0">
                <p className="benefits-styles">Non-energy benefits</p>
              </Col>
            </Row>
            <Row>
              <Col sm="4" align="center">
                <img src={customerSupportIcon} alt="" />
              </Col>
              <Col className="align-self-center p-0">
                <p className="benefits-styles">
                  Personalized end-to-end support
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ParticipantBenefits
