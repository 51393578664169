import { Container, Row, Col } from "react-bootstrap"
import contactUsImage from "./img/Asset_1.png"
import projIdentifyImage from "./img/Asset_2.png"
import projInstallImage from "./img/Asset_3.png"
import energySavingImage from "./img/Asset_4.png"
import incentivePaymentImage from "./img/Asset_5.png"

const HowItWorksHefi = () => {
  return (
    <div>
      <Container align="center">
        <Row className="pt-4">
          <h4>How It Works</h4>
        </Row>
        <Row>
          <Col>
            <div className="container1">
              <img id="hiw1" src={contactUsImage} alt="phone" width="200px" className="howitworks1" />
              <div id="hiwtext1" className="helveticaRegular">
                <h5 id="hiwheading1" className="helveticaBold">Contact Us</h5>
                <p className="helveticaRegular">Contact us for a quick and simple screening evaluation to confirm if you are eligible.</p>
              </div>
            </div>
          </Col>
          <Col>
            <div className="container2">
              <img id="hiw2" src={projIdentifyImage} alt="notepad" width="200px" className="howitworks2" />
              <div id="hiwtext2" className="helveticaRegular">
                <h5 id="hiwheading2" className="helveticaBold">Project Identification</h5>
                <p className="helveticaRegular">Program experts complete a detailed Energy Audit, develop an installation plan and reserve your incentives.</p>
              </div>
            </div>
          </Col>
          <Col>
            <div className="container3">
              <img id="hiw3" src={projInstallImage} alt="tools" width="200px" className="howitworks3" />
              <div id="hiwtext3" className="helveticaRegular">
                <h5 id="hiwheading3" className="helveticaBold">Project Installation</h5>
                <p className="helveticaRegular">You install selected measures using in-house staff, a qualified contractor of your choice, or a turnkey Delivery Partner.</p>
              </div>
            </div>
          </Col>
          <Col>
            <div className="container4">
              <img id="hiw4" src={energySavingImage} alt="bulb" width="200px" className="howitworks4" />
              <div id="hiwtext4" className="helveticaRegular">
                <h5 id="hiwheading4" className="helveticaBold">Energy Savings Verification</h5>
                <p className="helveticaRegular">Program experts confirm savings and provide you with the Verification Report detailing the achieved impacts.</p>
              </div>
            </div>
          </Col>
          <Col>
            <div className="container5">
              <img id="hiw5" src={incentivePaymentImage} alt="dollar" width="200px" className="howitworks5" />
              <div id="hiwtext5" className="helveticaRegular">
                <h5 id="hiwheading5" className="helveticaBold">Incentive Payment</h5>
                <p className="helveticaRegular">Program pays your financial incentives based on the verified savings.</p>
                <div id="rate" className="mt-2 helveticaBold">$0.12/kWh + $1.00/therm</div>
              </div>
            </div>
          </Col>

        </Row>
      </Container>
    </div>
  )
}

export default HowItWorksHefi
