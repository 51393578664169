import pgeLogo from "./img/3P_logo.png"
import { Container, Row, Col } from "react-bootstrap"

const Footer = () => {
  return (
    <div>
      <Container fluid className="footer-bg p-4">
        <Row>
          <Col sm={2} className="pb-3 mb-1" style={{ color: '#ffffff' }}>
            <img src={pgeLogo} alt="pge third party logo" width="150px" />
          </Col>
          <Col sm={10}>
            <p className="footer-style">
              “PG&E” refers to Pacific Gas and Electric Company, a subsidiary of
              PG&E Corporation. © 2021 Pacific Gas and Electric Company. This
              energy efficiency program is funded by California utility
              customers and administered through PG&E under the auspices of the
              California Public Utilities Commission. The program is implemented
              and managed by Nexant, Inc. and their authorized representatives.
              “PG&E” is a registered trademark of PG&E Corporation. PG&E is not
              responsible for any other content, names or marks in these program
              materials.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Footer
