import "./App.css"
import HefiHeroimg from "./components/HefiHeroimg"
import { Container, Row, Col } from "react-bootstrap"
import ParticipantBenefits from "./components/ParticipantBenefits"
import LimitedFunding from "./components/LimitedFunding"
import HowItWorksHefi from "./components/HowItWorksHefi"
import Eligibility from "./components/Eligibility"
import ContactSectionHefi from "./components/ContactSectionHefi"
import { AiFillPhone, AiTwotoneMail } from "react-icons/ai"
import ScrollTopArrow from "./components/ScrollTopArrow"
import React, { useEffect } from 'react';

const Hefi = () => {
  useEffect(() => {
    document.title = `PG&E EE Programs HEFI`;
  });

  return (
    <div>
      <HefiHeroimg />
      <Container>
        <Row className="p-2 pt-3">
          <Col lg={9}>
            <h3 style={{color: "#5e5e5e"}}>Healthcare Energy Fitness Initiative (HEFI)</h3>
            <p>
              HEFI is a comprehensive energy efficiency solution designed to
              support PG&E healthcare customers in saving money by optimizing
              their energy performance.
            </p>
          </Col>
          <Col lg={3}>
            <h4>Contact Us</h4>
            <div>
              <AiFillPhone className="phone-icon" role="img" aria-labelledby="phone_number_hefi" alt="phone image" />
              <span
                className="phone-link" id="phone_number_hefi"
                style={{ paddingLeft: ".5em", color: "#5e5e5e" }}
              >
                888.237.4334
              </span>
            </div>
            <div>
              <AiTwotoneMail className="mail-icon" role="img" aria-labelledby="email_box_hefi" alt="email box" />
              <span
                style={{ paddingLeft: ".5em", color: "#5e5e5e" }}
                className="mail-link" id="email_box_hefi"
              >
                HEFI@resource-innovations.com
              </span>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <Container>
        <Row className="p-2 pt-3">
          <h2>Healthcare Energy Fitness Initiative (HEFI)</h2>
        </Row>
        <Row className="p-2">
          <p>
            HEFI is a comprehensive energy efficiency solution designed to
            support PG&E healthcare customers in saving money by optimizing
            their energy performance.
          </p>
        </Row>
      </Container> */}

      <section className="bg-gray">
        <Container>
          <h4 className="pt-3">Our Approach</h4>
          <Row className="pb-2">
            <p>
              This unique Program provides no-cost expert technical services,
              end-to-end project support, and lucrative financial incentives to
              qualifying customers. Our team of experienced energy experts and
              highly qualified Delivery Partners, along with your PG&E Account
              Manager, will help you get projects identified, approved, funded,
              and completed so you can focus on your core business priorities.{" "}
            </p>

            <p>
              We can serve all types of facilities and space uses in your
              portfolio including hospitals, outpatient, offices, laboratories,
              data centers, and more. Our team has extensive experience
              completing energy projects across large multi-site healthcare
              systems.
            </p>

            <p>
              Our comprehensive yet streamlined approach will identify projects
              ranging from low-cost controls optimization to equipment retrofits
              and system replacements. Our team of experts can handle it all:
              from complex HVAC systems and central plants, to advanced
              lighting, specialized data center and lab equipment,
              refrigeration, sensitive process equipment and much more. Focusing
              on patient care while dealing with the ongoing financing and
              operational challenges of COVID-19, regulatory compliance,
              legislative changes, seismic retrofits, and maintaining resiliency
              is difficult. So let us help make saving energy easy!
            </p>
          </Row>
        </Container>
      </section>
      <ParticipantBenefits />
      <LimitedFunding />
      <HowItWorksHefi />
      <Eligibility />
      <ContactSectionHefi />
      <ScrollTopArrow />
    </div>
  )
}

export default Hefi
