import { Navbar, Nav, Container } from "react-bootstrap"
import headerLogo from "./img/RI_Logo_H_Lockup_One_Color_White_KO.png"
import { NavLink } from 'react-router-dom'

const Navigation = () => {
  return (
    <div>
      <Navbar collapseOnSelect expand="sm" className="nav-styles">
        <Container fluid className="p-2">
          <Navbar.Brand>
            <img
              src={headerLogo}
              alt="Resource innovations - Reimagining tomorrow with Nexant today logo"
              className="d-inline-block align-top"
              width="200px"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="navbar-dark navbar-hamburger"
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="justify-content-end">
              <NavLink to="/" className="nav-link-styles m-2">
                  Home
              </NavLink>
              <NavLink to="/aep" className="nav-link-styles m-2">
                  Advanced Energy Program
              </NavLink>

              <NavLink to="/hefi" className="nav-link-styles m-2">
                  Healthcare Energy Fitness Initiative
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default Navigation
