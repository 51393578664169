import Card from "react-bootstrap/Card"
import CardGroup from "react-bootstrap/CardGroup"
import Button from "react-bootstrap/Button"
import { Container, Col, Row } from "react-bootstrap"
import aepCardimage from "./img/aep-card-header.png"
import hefiCardimage from "./img/hefi-card-header.png"

const LandingBody = () => {
  return (
    <div>
      <Container>
        <Row className="pt-4">
          <h3 style={{color: "#5e5e5e"}}>
            Energy & Cost Saving Solutions for High-Tech, Bio-Tech, and
            Healthcare Customers
          </h3>
        </Row>
        <Row className="pt-3">
          <p>
            Our unique Programs provide no-cost expert technical services,
            end-to-end project support, and lucrative financial incentives to
            qualifying customers. Our team of experienced energy experts and
            highly qualified Delivery Partners, along with your PG&E Account
            Manager, will help you get projects identified, approved, funded,
            and completed so you can focus on your core business priorities.
            Click on the applicable Program below to learn more.
          </p>
        </Row>
      </Container>
      <Container className="pt-3" align="center">
        <Row>
          <CardGroup>
          <Col md={6} className="pb-4">
            <Card style={{ width: "20rem" }}>
              <Card.Img variant="top" src={aepCardimage} alt=""/>
              <Card.Body align="left">
                <h5 className="card-title">Advanced Energy Program</h5>
                <Card.Text>
                  A comprehensive energy efficiency solution designed to support
                  PG&E High-Tech and Bio-Tech (HTBT) customers in saving money by
                  achieving next-generation energy performance.
                </Card.Text>
                <Button href="/aep" className="button-style" variant="primary" aria-label="More About AEP">
                  More About AEP
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card style={{ width: "20rem" }} className="mb-5">
              <Card.Img variant="top" src={hefiCardimage} alt="" />
              <Card.Body align="left">
                <h5 className="card-title">Healthcare Energy Fitness</h5>
                <Card.Text id="learn-more" className="pb-5">
                    A comprehensive energy efficiency solution designed to support
                    PG&E healthcare customers in saving money by optimizing
                    their energy performance.
                </Card.Text>
                <Button href="/hefi" className="button-style" variant="primary" aria-label="More About HEFI">
                  More About HEFI
                </Button>
              </Card.Body>
            </Card>
          </Col>
          </CardGroup>
        </Row>
      </Container>
    </div>
  )
}

export default LandingBody
