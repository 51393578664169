import { Row, Col, Container } from "react-bootstrap"
import serverImage from "./img/serverRoom.png"

const Eligibility = () => {
  return (
    <div>
      <section className="bg-gray">
        <Container>
          <Row>
            <Col md="6" align="center" className="align-self-center">
              <Row className="p-3 ">
                <h4>Eligibility</h4>
              </Row>
              <Row>
                <p style={{ fontSize: 16, fontWeight: "bold" }}>
                  High-Tech/Bio-Tech customers, including: Technology
                  manufacturers, aerospace, scientific R&D, biopharmaceuticals,
                  academic research, and more.
                </p>
              </Row>
              <hr className="green" />
              <Row>
                <p style={{ fontSize: 16, fontWeight: "bold" }}>
                  Customer must receive electric and/or gas service from PG&E
                  and pay the Public Goods Charge or Public Purpose Program
                  Surcharge.
                </p>
              </Row>
              <hr className="green" />
              <Row>
                <p className="pb-3" style={{ fontSize: 16, fontWeight: "bold" }}>
                  Eligible customers will be evaluated on case-by-case basis for
                  acceptance into the program. 
                </p>
              </Row>
            </Col>
            <Col xs lg="5" className="p-0">
              <img
                src={serverImage}
                alt="doctor on computer with instruments on table"
                width="100%"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default Eligibility
