import Header from "./Header"
import LandingBody from "./LandingBody"
import ScrollTopArrow from "./ScrollTopArrow"

const Home = () => {
  return (
    <div>
      <Header />
      <LandingBody />
      <ScrollTopArrow />
    </div>
  )
}

export default Home
